/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
:root {
  --fontFamily: "Work Sans", sans-serif;
  --mainColor: #e28593;
  --mainColorHover: #d36777;
  --mainColor2: #30eded;
  --optionalColor: #666666;
  --whiteColor: #ffffff;
  --blackColor: #221638;
  --fontSize: 14px !important;
  --transition: 0.5s;
}

body {
  padding: 0;
  margin: 0;
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a {
  color: var(--blackColor);
  transition: var(--transition);
  text-decoration: none;
  outline: 0 !important;
}
a:hover {
  color: var(--mainColor);
  text-decoration: none;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  color: var(--optionalColor);
  font-size: var(--fontSize);
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}

.mr-3 {
  margin-right: 8px;
}

.mr-2 {
  margin-right: 5px;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.container {
  max-width: 1230px;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

.bg-f5f5f5 {
  background-color: #f5f5f5;
}

.bg-main-color {
  background-color: var(--mainColor2);
}
.bg-main-color .section-title p {
  color: var(--blackColor);
}

/*section-title*/
.section-title {
  text-align: center;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}
.section-title h2 {
  font-size: 30px;
  margin-bottom: 15px;
}
.section-title h2 span {
  color: var(--mainColor);
}
.section-title.text-start {
  position: relative;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 40px;
}
.section-title.text-start h2 {
  margin-bottom: 0;
}
.section-title.text-start .link-btn {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
}
.section-title.text-start .link-btn i {
  transition: var(--transition);
  color: var(--blackColor);
}
.section-title.text-start .link-btn:hover i {
  margin-left: 5px;
  color: var(--mainColor);
}

.alert-danger {
  background: none;
  border: none;
  color: #dc3545;
  padding: 0;
  font-weight: 500;
  font-size: 0.875em;
}

/*default-btn*/
.default-btn {
  display: inline-block;
  transition: var(--transition);
  border: none;
  text-align: center;
  border: 1px solid #96e3e3;
  box-shadow: 4px 4px #b2eaea;
  background-color: var(--whiteColor);
  color: var(--blackColor);
  font-size: var(--fontSize);
  font-weight: 600;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 13px;
  padding-bottom: 13px;
}
.default-btn:hover {
  border-color: var(--mainColor);
  box-shadow: 4px 4px var(--mainColor);
  color: var(--blackColor);
}

/*form-control*/
.form-control {
  border-radius: 0;
  background-color: #f5f5f5 !important;
  box-shadow: unset !important;
  transition: var(--transition);
  border: none !important;
  height: 45px;
  padding-left: 15px;
  color: var(--blackColor);
  font-size: 14.5px;
  font-weight: 400;
}
.form-control::placeholder {
  color: #999999;
  transition: var(--transition);
}
.form-control:focus::placeholder {
  color: transparent;
}

textarea.form-control {
  padding-top: 15px;
  height: auto;
}

/*keyframes-css*/
@keyframes moveleftbounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes cluster-animation {
  0%, 100% {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.1);
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
/*extra-css*/
.feedback-slides .owl-theme .owl-nav {
  margin-top: 0;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.feedback-slides .owl-theme .owl-nav [class*=owl-] {
  color: var(--blackColor);
  font-size: 18px;
  margin: 0;
  padding: 0;
  background: var(--whiteColor);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  transition: var(--transition);
  border: 1px solid var(--mainColor);
  position: absolute;
  left: -65px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: -2px 2px var(--mainColor);
}
.feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -65px;
  box-shadow: 2px 2px var(--mainColor);
}
.feedback-slides .owl-theme .owl-nav [class*=owl-]:hover {
  box-shadow: 2px 2px transparent;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.feedback-slides .owl-theme .owl-nav [class*=owl-].disabled {
  cursor: not-allowed;
  background-color: var(--whiteColor);
  color: var(--blackColor);
}
.feedback-slides .owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}
.feedback-slides .owl-theme .owl-dots {
  margin-top: 5px;
}
.feedback-slides .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  background: #b9b9b9;
  border-radius: 50%;
  transition: var(--transition);
}
.feedback-slides .owl-theme .owl-dots .owl-dot:hover span, .feedback-slides .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.listings-slides .owl-theme .owl-nav {
  margin-top: 0;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.listings-slides .owl-theme .owl-nav [class*=owl-] {
  color: var(--blackColor);
  font-size: 18px;
  margin: 0;
  padding: 0;
  background: var(--whiteColor);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  transition: var(--transition);
  border: 1px solid var(--mainColor);
  position: absolute;
  left: -65px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: -2px 2px var(--mainColor);
}
.listings-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -65px;
  box-shadow: 2px 2px var(--mainColor);
}
.listings-slides .owl-theme .owl-nav [class*=owl-]:hover {
  box-shadow: 2px 2px transparent;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.listings-slides .owl-theme .owl-nav [class*=owl-].disabled {
  cursor: not-allowed;
  background-color: var(--whiteColor);
  color: var(--blackColor);
}
.listings-slides .owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}
.listings-slides .owl-theme .owl-dots {
  margin-top: 5px;
}
.listings-slides .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  background: #b9b9b9;
  border-radius: 50%;
  transition: var(--transition);
}
.listings-slides .owl-theme .owl-dots .owl-dot:hover span, .listings-slides .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.single-listings-box .listings-image .owl-theme {
  z-index: 3;
}
.single-listings-box .listings-image .owl-theme .owl-nav {
  margin-top: 0;
  opacity: 0 !important;
  visibility: hidden !important;
  transition: var(--transition);
}
.single-listings-box .listings-image .owl-theme .owl-nav [class*=owl-] {
  color: var(--blackColor);
  font-size: 14px;
  margin: 0;
  transition: var(--transition);
  padding: 0;
  background: #d1d2d0;
  border-radius: 50%;
  width: 30px;
  box-shadow: unset !important;
  border: none !important;
  height: 30px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  left: 20px;
  top: 63%;
  transform: translateY(-63%);
  z-index: 3;
}
.single-listings-box .listings-image .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
}
.single-listings-box .listings-image .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.single-listings-box .listings-image:hover .owl-theme .owl-nav {
  opacity: 1 !important;
  visibility: visible !important;
}

.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button {
  border: none;
  height: 60px;
  display: block;
  margin-bottom: 0;
  overflow: unset;
  line-height: 60px;
  min-height: auto;
  border-radius: 0;
  color: #666666;
  background-color: var(--whiteColor);
  font-size: 15px;
  font-weight: 400;
  padding-left: 30px;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 0;
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: var(--mainColor);
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 0;
  padding-top: 0;
  padding-left: 15px;
  height: 210px;
  overflow-y: scroll;
  padding-bottom: 15px;
  background-color: var(--whiteColor);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: start;
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: start;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px;
}

.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button {
  border: none;
  height: 60px;
  display: block;
  margin-bottom: 0;
  overflow: unset;
  line-height: 60px;
  min-height: auto;
  border-radius: 0;
  color: #666666;
  background-color: var(--whiteColor);
  font-size: 15px;
  font-weight: 400;
  padding-left: 30px;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 0;
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: var(--mainColor);
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 0;
  padding-top: 0;
  padding-left: 15px;
  height: 210px;
  overflow-y: scroll;
  padding-bottom: 15px;
  background-color: var(--whiteColor);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: start;
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: start;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px;
}

.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-button {
  border: none !important;
  display: block !important;
  margin-bottom: 0 !important;
  overflow: unset !important;
  height: 45px !important;
  line-height: 45px !important;
  min-height: auto !important;
  border-radius: 3px !important;
  color: var(--mainColor) !important;
  background-color: #f1f1f1 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-bottom: 0 !important;
  padding-top: 1px !important;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 20px;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: #838383;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none !important;
  border-radius: 3px !important;
  width: 100% !important;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 0;
  padding-left: 15px !important;
  padding-bottom: 15px;
  background-color: var(--whiteColor) !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: start !important;
  background-color: var(--whiteColor) !important;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: start;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.single-footer-widget .languages-switch .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px;
}

.feedback-area.bg-image {
  background-image: url(assets/img/feedback-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.feedback-area.bg-image .section-title h2 {
  color: var(--whiteColor);
}
.feedback-area.bg-image .section-title p {
  color: var(--whiteColor);
}

.divider2 {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #f9f9f9;
  bottom: -100px;
  left: 0;
  z-index: -1;
  transform: scaleY(-1);
}

.divider {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #f9f9f9;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.page-title-area {
  padding-bottom: 130px;
  padding-top: 240px;
  background-color: var(--blackColor);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.page-title-area.page-title-style-two {
  padding-top: 140px;
  padding-bottom: 120px;
}

.page-title-content {
  text-align: center;
}
.page-title-content h2 {
  font-size: 37px;
  margin-bottom: 0;
  color: var(--whiteColor);
}
.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 22px;
}
.page-title-content ul li {
  display: inline-block;
  color: var(--whiteColor);
  font-weight: 500;
  position: relative;
  margin-left: 13px;
  margin-right: 13px;
}
.page-title-content ul li a {
  color: var(--whiteColor);
  display: inline-block;
}
.page-title-content ul li::before {
  content: "\f11c";
  position: absolute;
  right: -18px;
  top: 3.2px;
  font-family: Flaticon;
  font-size: 11px;
}
.page-title-content ul li:last-child::before {
  display: none;
}

.page-title-bg {
  background-color: #f6f6f6;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}
.page-title-bg h2 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 800;
}
.page-title-bg form {
  background-color: var(--whiteColor);
  box-shadow: 5px 5px #30eded;
  border-radius: 5px;
  max-width: 900px;
  padding-left: 20px;
  padding-right: 12px;
  margin-left: auto;
  margin-top: 30px;
  margin-right: auto;
}
.page-title-bg form .form-group {
  margin: 0;
  position: relative;
  border-right: 1px solid #30eded;
  margin-left: 12px;
}
.page-title-bg form .form-group label {
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: 19px;
  line-height: 1;
  font-size: 23px;
  color: #646464;
  z-index: 2;
}
.page-title-bg form .form-group.category-select {
  border-right: none;
  padding-right: 20px;
}
.page-title-bg form .form-group .form-control {
  border: none !important;
  color: var(--blackColor);
  box-shadow: unset !important;
  background-color: transparent !important;
  height: 60px;
  line-height: 60px;
  font-size: var(--fontSize);
  font-weight: 400;
  padding-top: 2px;
  padding-bottom: 0;
  padding-left: 30px;
  padding-right: 15px;
}
.page-title-bg form .form-group .form-control::placeholder {
  transition: var(--transition);
  color: #666666;
}
.page-title-bg form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.page-title-bg form .form-group .nice-select {
  border-radius: 0;
  border: none;
  float: unset;
  height: 60px;
  line-height: 60px;
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  padding-left: 30px;
  padding-right: 15px;
  padding-bottom: 0;
  padding-top: 1px;
}
.page-title-bg form .form-group .nice-select:after {
  border-color: var(--mainColor);
  height: 8px;
  margin-top: -5px;
  right: 0;
  width: 8px;
}
.page-title-bg form .form-group .nice-select .list {
  background-color: var(--whiteColor);
  border-radius: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  height: 210px;
  overflow-y: scroll;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-title-bg form .form-group .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: var(--blackColor);
  position: relative;
  transition: var(--transition);
  padding-left: 38px;
  padding-right: 15px;
  font-size: var(--fontSize);
  font-weight: 500;
}
.page-title-bg form .form-group .nice-select .list .option:hover {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor);
}
.page-title-bg form .form-group .nice-select .list .option:hover::before {
  color: var(--whiteColor);
}
.page-title-bg form .form-group .nice-select .list .option.focus, .page-title-bg form .form-group .nice-select .list .option.selected.focus {
  background-color: transparent !important;
  color: var(--blackColor);
}
.page-title-bg form .form-group .nice-select .list .option::before {
  content: "\ea0f";
  position: absolute;
  left: 12px;
  top: -1px;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  color: var(--mainColor);
  font-family: "boxicons";
  font-size: 20px;
}
.page-title-bg form .form-group .nice-select .list .option.selected::before {
  opacity: 1;
  visibility: visible;
}
.page-title-bg form .form-group .nice-select .list .option.selected:hover {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor);
}
.page-title-bg form .form-group .nice-select .list .option.selected:hover::before {
  color: var(--whiteColor);
}
.page-title-bg form .col-lg-4 .form-group {
  margin-left: 0;
}
.page-title-bg form .submit-btn {
  padding-left: 5px;
}
.page-title-bg form .submit-btn button {
  display: block;
  width: 100%;
  background-color: var(--mainColor);
  border: none;
  color: var(--whiteColor);
  font-size: var(--fontSize);
  font-weight: 600;
  padding: 12px 15px;
  border-radius: 5px;
  transition: var(--transition);
}
.page-title-bg form .submit-btn button:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}
.page-title-bg.map-home-area {
  text-align: start;
  padding-top: 0;
  padding-bottom: 0;
}
.page-title-bg.map-home-area #main-full-map {
  height: 600px;
}

.pagination-area {
  margin-top: 35px;
}
.pagination-area .ngx-pagination {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.pagination-area .ngx-pagination li {
  font-weight: 600;
  color: var(--blackColor);
  margin-left: 4px;
  margin-right: 4px;
}
.pagination-area .ngx-pagination li.disabled {
  color: var(--blackColor);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--whiteColor);
  padding: 8px 15px;
}
.pagination-area .ngx-pagination li a {
  color: var(--blackColor);
  transition: var(--transition);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--whiteColor);
  padding: 8px 15px;
}
.pagination-area .ngx-pagination li a:hover, .pagination-area .ngx-pagination li a.current {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.pagination-area .ngx-pagination li.current {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  padding: 8px 15px;
}

.widget-area {
  padding-left: 10px;
}
.widget-area.widget-left-sidebar {
  padding-right: 10px;
  padding-left: 0;
}
.widget-area .widget {
  background-color: var(--whiteColor);
  padding: 20px;
  margin-bottom: 30px;
}
.widget-area .widget:last-child {
  margin-bottom: 0;
}
.widget-area .widget .widget-title {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 7px;
  border-bottom: 1px solid #eeeeee;
  font-size: 20px;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: var(--mainColor);
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .search-field {
  height: 50px;
  color: var(--blackColor);
  background-color: #f2f4f5;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 2px 0 0 15px;
  border: none;
  transition: var(--transition);
  font-weight: 400;
}
.widget-area .widget_search form .search-field::placeholder {
  color: var(--optionalColor);
  transition: var(--transition);
}
.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}
.widget-area .widget_search form .search-field[type=search]::-ms-clear, .widget-area .widget_search form .search-field[type=search]::-ms-reveal, .widget-area .widget_search form .search-field[type=search]::-webkit-search-decoration {
  display: none;
}
.widget-area .widget_search form button {
  border: none;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  height: 40px;
  width: 40px;
  position: absolute;
  right: 5px;
  padding: 0;
  transition: var(--transition);
  top: 5px;
  font-size: 20px;
}
.widget-area .widget_search form button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget-area .widget_search form button:hover, .widget-area .widget_search form button:focus {
  border-radius: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.widget-area .widget_vesax_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_vesax_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_vesax_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_vesax_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}
.widget-area .widget_vesax_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_vesax_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/blog/blog4.jpg);
}
.widget-area .widget_vesax_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/blog/blog5.jpg);
}
.widget-area .widget_vesax_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/blog/blog6.jpg);
}
.widget-area .widget_vesax_posts_thumb .item .thumb::before, .widget-area .widget_vesax_posts_thumb .item .thumb::after {
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.widget-area .widget_vesax_posts_thumb .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}
.widget-area .widget_vesax_posts_thumb .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}
.widget-area .widget_vesax_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}
.widget-area .widget_vesax_posts_thumb .item .info span {
  display: block;
  color: var(--optionalColor);
  text-transform: uppercase;
  margin-top: -2px;
  margin-bottom: 5px;
  font-size: 13px;
}
.widget-area .widget_vesax_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 16px;
}
.widget-area .widget_vesax_posts_thumb .item .info .title a {
  display: inline-block;
}
.widget-area .widget_vesax_posts_thumb .item:hover .thumb::before, .widget-area .widget_vesax_posts_thumb .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  color: var(--blackColor);
  padding-left: 16px;
  font-weight: 600;
  font-size: var(--fontSize);
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: var(--mainColor);
  height: 8px;
  width: 8px;
  content: "";
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_categories ul li a {
  color: var(--blackColor);
  display: inline-block;
}
.widget-area .widget_categories ul li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_categories ul li .post-count {
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 17px;
}
.widget-area .tagcloud a {
  display: inline-block;
  background: #f5f5f5;
  color: var(--blackColor);
  padding: 7px 15px;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px !important;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.widget-area .widget_instagram ul {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}
.widget-area .widget_instagram ul li {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}
.widget-area .widget_instagram ul li .box {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.widget-area .widget_instagram ul li .box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}
.widget-area .widget_instagram ul li .box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  color: var(--whiteColor);
  z-index: 2;
}
.widget-area .widget_instagram ul li .box img {
  transition: var(--transition);
}
.widget-area .widget_instagram ul li .box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--mainColor);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.widget-area .widget_instagram ul li .box:hover::before {
  opacity: 0.5;
  visibility: visible;
}
.widget-area .widget_instagram ul li .box:hover img {
  transform: scale(1.3);
}
.widget-area .widget_instagram ul li .box:hover i {
  opacity: 1;
  visibility: visible;
}

.vesax-grid-sorting .ordering .ngx-dropdown-container {
  width: 215px !important;
  display: inline-block !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button {
  border: 1px solid #eeeeee !important;
  margin-bottom: 0 !important;
  overflow: unset !important;
  min-height: auto !important;
  border-radius: 5px !important;
  height: 45px !important;
  line-height: 43px !important;
  display: block !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  background: #f8f8f8 !important;
  font-size: var(--fontSize) !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 12px !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: var(--mainColor) !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button:hover {
  border-color: var(--mainColor) !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none !important;
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
  background-color: var(--whiteColor) !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: start !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: start;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.vesax-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px !important;
}

.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button {
  border: 1px solid #eeeeee !important;
  margin-bottom: 0 !important;
  overflow: unset !important;
  min-height: auto !important;
  border-radius: 5px !important;
  height: 45px !important;
  line-height: 43px !important;
  display: block !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  background: #f8f8f8 !important;
  font-size: var(--fontSize) !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 12px !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: var(--mainColor) !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-button:hover {
  border-color: var(--mainColor) !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none !important;
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
  background-color: var(--whiteColor) !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: start !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: start;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.billing-details .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px !important;
}

.gallery-slides .owl-theme {
  margin-bottom: 35px;
  margin-top: 25px;
}
.gallery-slides .owl-theme .owl-nav {
  margin-top: 0;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.gallery-slides .owl-theme .owl-nav [class*=owl-] {
  color: var(--blackColor);
  font-size: 18px;
  margin: 0;
  padding: 0;
  background: var(--whiteColor);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  transition: var(--transition);
  border: 1px solid var(--mainColor);
  position: absolute;
  left: 20px;
  top: 55%;
  transform: translateY(-55%);
  box-shadow: -2px 2px var(--mainColor);
}
.gallery-slides .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
  box-shadow: 2px 2px var(--mainColor);
}
.gallery-slides .owl-theme .owl-nav [class*=owl-]:hover {
  box-shadow: 2px 2px transparent;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.gallery-slides .owl-theme .owl-nav [class*=owl-].disabled {
  cursor: not-allowed;
  background-color: var(--whiteColor);
  color: var(--blackColor);
}
.gallery-slides .owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-button {
  border: none;
  height: 60px;
  display: block;
  margin-bottom: 0;
  overflow: unset;
  line-height: 60px;
  min-height: auto;
  border-radius: 0;
  color: #666666;
  background-color: var(--whiteColor);
  font-size: 15px;
  font-weight: 400;
  padding-left: 30px;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 0;
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: var(--mainColor);
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 0;
  padding-top: 0;
  padding-left: 15px;
  height: 210px;
  overflow-y: scroll;
  padding-bottom: 15px;
  background-color: var(--whiteColor);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: start;
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: start;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px;
}

.listings-widget-area {
  padding-right: 50px;
}
.listings-widget-area.widget-right-sidebar {
  padding-right: 0;
  padding-left: 50px;
}
.listings-widget-area .widget {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.listings-widget-area .widget .widget-title {
  font-size: 19px;
  margin-bottom: 25px;
  position: relative;
}
.listings-widget-area .widget .widget-title::before {
  font-family: Flaticon;
  content: "\f106";
  position: absolute;
  right: 0;
  top: 0;
  font-size: 19px;
  color: var(--blackColor);
}
.listings-widget-area .widget:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.listings-widget-area .widget_filters ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.listings-widget-area .widget_filters ul li {
  display: inline-block;
  margin-right: -1px;
}
.listings-widget-area .widget_filters ul li button {
  display: block;
  background-color: transparent;
  border: 1px solid #dedede;
  transition: var(--transition);
  padding: 7px 20px 6px;
  font-size: var(--fontSize);
  font-weight: 600;
}
.listings-widget-area .widget_filters ul li button:hover, .listings-widget-area .widget_filters ul li button.active {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}
.listings-widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.listings-widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 11px;
  color: var(--optionalColor);
}
.listings-widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.listings-widget-area .widget_categories ul li input[type=checkbox] {
  --active: var(--mainColor);
  --active-inner: var(--whiteColor);
  --focus: 2px rgba(39, 94, 254, 0.3);
  --border: #d5d5d5;
  --border-hover: var(--mainColor);
  --background: var(--whiteColor);
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 19px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}
.listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch) {
  border-radius: 0;
}
.listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch), .listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch) {
  width: 19px;
}
.listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch):after {
  width: 5px;
  height: 9px;
  border: 2px solid var(--active-inner);
  border-top: 0;
  border-left: 0;
  left: 6px;
  top: 3.5px;
  -webkit-transform: rotate(var(--r, 20deg));
  transform: rotate(var(--r, 20deg));
}
.listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch):after, .listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch):after {
  opacity: var(--o, 0);
}
.listings-widget-area .widget_categories ul li input[type=checkbox]:after, .listings-widget-area .widget_categories ul li input[type=checkbox]:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
}
.listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch):checked {
  --r: 43deg;
}
.listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch):checked, .listings-widget-area .widget_categories ul li input[type=checkbox]:not(.switch):checked {
  --o: 1;
}
.listings-widget-area .widget_categories ul li input[type=checkbox]:checked, .listings-widget-area .widget_categories ul li input[type=checkbox]:checked {
  --b: var(--active);
  --bc: var(--active);
  --d-o: 0.3s;
  --d-t: 0.6s;
  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}
.listings-widget-area .widget_categories ul li label {
  cursor: pointer;
  position: relative;
  top: -0.8px;
  margin-bottom: 0;
  margin-left: 10px;
}
.listings-widget-area .widget_categories ul li span {
  display: inline-block;
  cursor: pointer;
  color: var(--mainColor);
  transition: var(--transition);
  font-weight: 600;
}
.listings-widget-area .widget_categories ul li span:hover {
  color: var(--mainColor2);
}
.listings-widget-area .widget_categories ul li.hide {
  display: none;
}
.listings-widget-area .widget_categories ul li.hide.active {
  display: block;
}
.listings-widget-area .widget_categories ul li.see-all-btn.active {
  display: none;
}
.listings-widget-area .widget_features ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.listings-widget-area .widget_features ul li {
  position: relative;
  margin-bottom: 11px;
  color: var(--optionalColor);
}
.listings-widget-area .widget_features ul li:last-child {
  margin-bottom: 0;
}
.listings-widget-area .widget_features ul li input[type=checkbox] {
  --active: var(--mainColor);
  --active-inner: var(--whiteColor);
  --focus: 2px rgba(39, 94, 254, 0.3);
  --border: #d5d5d5;
  --border-hover: var(--mainColor);
  --background: var(--whiteColor);
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 19px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}
.listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch) {
  border-radius: 0;
}
.listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch), .listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch) {
  width: 19px;
}
.listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch):after {
  width: 5px;
  height: 9px;
  border: 2px solid var(--active-inner);
  border-top: 0;
  border-left: 0;
  left: 6px;
  top: 3.5px;
  -webkit-transform: rotate(var(--r, 20deg));
  transform: rotate(var(--r, 20deg));
}
.listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch):after, .listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch):after {
  opacity: var(--o, 0);
}
.listings-widget-area .widget_features ul li input[type=checkbox]:after, .listings-widget-area .widget_features ul li input[type=checkbox]:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
}
.listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch):checked {
  --r: 43deg;
}
.listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch):checked, .listings-widget-area .widget_features ul li input[type=checkbox]:not(.switch):checked {
  --o: 1;
}
.listings-widget-area .widget_features ul li input[type=checkbox]:checked, .listings-widget-area .widget_features ul li input[type=checkbox]:checked {
  --b: var(--active);
  --bc: var(--active);
  --d-o: 0.3s;
  --d-t: 0.6s;
  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}
.listings-widget-area .widget_features ul li label {
  cursor: pointer;
  position: relative;
  top: -0.8px;
  margin-bottom: 0;
  margin-left: 10px;
}
.listings-widget-area .widget_features ul li span {
  display: inline-block;
  cursor: pointer;
  color: var(--mainColor);
  transition: var(--transition);
  font-weight: 600;
}
.listings-widget-area .widget_features ul li span:hover {
  color: var(--mainColor2);
}
.listings-widget-area .widget_features ul li.hide {
  display: none;
}
.listings-widget-area .widget_features ul li.hide.active {
  display: block;
}
.listings-widget-area .widget_features ul li.see-all-btn.active {
  display: none;
}
.listings-widget-area .widget_distance ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.listings-widget-area .widget_distance ul li {
  position: relative;
  margin-bottom: 11px;
  color: var(--optionalColor);
}
.listings-widget-area .widget_distance ul li:last-child {
  margin-bottom: 0;
}
.listings-widget-area .widget_distance ul li input[type=checkbox] {
  --active: var(--mainColor);
  --active-inner: var(--whiteColor);
  --focus: 2px rgba(39, 94, 254, 0.3);
  --border: #d5d5d5;
  --border-hover: var(--mainColor);
  --background: var(--whiteColor);
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 19px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
}
.listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch) {
  border-radius: 0;
}
.listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch), .listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch) {
  width: 19px;
}
.listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch):after {
  width: 5px;
  height: 9px;
  border: 2px solid var(--active-inner);
  border-top: 0;
  border-left: 0;
  left: 6px;
  top: 3.5px;
  -webkit-transform: rotate(var(--r, 20deg));
  transform: rotate(var(--r, 20deg));
}
.listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch):after, .listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch):after {
  opacity: var(--o, 0);
}
.listings-widget-area .widget_distance ul li input[type=checkbox]:after, .listings-widget-area .widget_distance ul li input[type=checkbox]:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
}
.listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch):checked {
  --r: 43deg;
}
.listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch):checked, .listings-widget-area .widget_distance ul li input[type=checkbox]:not(.switch):checked {
  --o: 1;
}
.listings-widget-area .widget_distance ul li input[type=checkbox]:checked, .listings-widget-area .widget_distance ul li input[type=checkbox]:checked {
  --b: var(--active);
  --bc: var(--active);
  --d-o: 0.3s;
  --d-t: 0.6s;
  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}
.listings-widget-area .widget_distance ul li label {
  cursor: pointer;
  position: relative;
  top: -0.8px;
  margin-bottom: 0;
  margin-left: 10px;
}
.listings-widget-area .widget_distance ul li span {
  display: inline-block;
  cursor: pointer;
  color: var(--mainColor);
  transition: var(--transition);
  font-weight: 600;
}
.listings-widget-area .widget_distance ul li span:hover {
  color: var(--mainColor2);
}
.listings-widget-area .widget_distance ul li.hide {
  display: none;
}
.listings-widget-area .widget_distance ul li.hide.active {
  display: block;
}
.listings-widget-area .widget_distance ul li.see-all-btn.active {
  display: none;
}

.listings-grid-sorting .ordering .ngx-dropdown-container {
  width: 215px !important;
  display: inline-block !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button {
  border: 1px solid #eeeeee !important;
  margin-bottom: 0 !important;
  overflow: unset !important;
  min-height: auto !important;
  border-radius: 5px !important;
  height: 45px !important;
  line-height: 43px !important;
  display: block !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  background: #f8f8f8 !important;
  font-size: var(--fontSize) !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
  right: 12px !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
  top: 3px !important;
  border-color: var(--mainColor) !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button:hover {
  border-color: var(--mainColor) !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container {
  border: none !important;
  width: 100% !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
  background-color: var(--whiteColor) !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container .search-container input {
  border-color: #eeeeee !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  padding-left: 0 !important;
  text-align: start !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li {
  position: relative !important;
  color: var(--blackColor) !important;
  transition: var(--transition) !important;
  text-align: start;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 0 !important;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li:first-child {
  padding-top: 0 !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul li:hover {
  color: var(--mainColor) !important;
}
.listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-list-container ul.selected-items li {
  background-color: transparent !important;
  color: var(--mainColor) !important;
  margin-bottom: 2px !important;
}

.add-listings-box .file-upload-box .dropzone {
  position: relative;
  border: 2px dashed #eeeeee;
  border-radius: 3px;
  background: rgba(14, 198, 198, 0.03);
  transition: all 0.3s linear;
  display: inline-block;
  width: 100%;
  margin: 0;
}
.add-listings-box .file-upload-box .dropzone button {
  color: var(--blackColor);
  position: relative;
  padding-top: 52px;
  font-size: 16px;
  font-weight: 500;
}
.add-listings-box .file-upload-box .dropzone button::before {
  content: "\eb8a";
  font-family: "boxicons";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: var(--mainColor);
  font-size: 35px;
}
.add-listings-box .file-upload-box .dropzone:hover {
  border-color: var(--mainColor);
}
.add-listings-box .subHeading {
  border-bottom: 1px solid #ddd;
  padding: 7px;
  margin-bottom: 15px !important;
  display: block;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .summary2-col {
    display: flex;
    justify-content: space-between;
  }
  body {
    font-size: 13px;
  }
  p {
    font-size: 13px;
  }
  .container {
    max-width: 100%;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pt-70 {
    padding-top: 30px;
  }
  .pb-70 {
    padding-bottom: 30px;
  }
  .section-title {
    max-width: 100%;
    margin-bottom: 40px;
    margin-top: -3px;
  }
  .section-title h2 {
    font-size: 23px;
    margin-bottom: 10px;
    line-height: 1.4;
  }
  .section-title.text-start {
    text-align: center !important;
  }
  .section-title.text-start .link-btn {
    position: relative;
    top: 0;
    transform: translateY(0);
    margin-top: 12px;
  }
  .form-control {
    height: 45px;
    padding-left: 10px;
    font-size: 13px;
  }
  .default-btn {
    font-size: 13px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .navbar-area.navbar-style-two .vesax-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--whiteColor);
  }
  .navbar-area.navbar-style-two .vesax-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--whiteColor);
  }
  .vesax-responsive-nav {
    display: block;
  }
  .vesax-responsive-nav .vesax-responsive-menu {
    position: relative;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav {
    margin-top: 57px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul li a.active {
    color: var(--mainColor);
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14.5px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 306px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .vesax-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--blackColor);
  }
  .vesax-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--blackColor);
  }
  .category-area.pb-0 {
    padding-bottom: 30px !important;
  }
  .divider {
    display: none;
  }
  .divider2 {
    display: none;
  }
  .feedback-slides .owl-theme .owl-dots {
    display: none;
  }
  .feedback-slides .owl-theme .owl-nav {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 16px;
    margin: 0 5px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    position: relative;
    left: 0;
    top: 0;
    transform: unset;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }
  .page-title-area {
    padding-bottom: 80px;
    padding-top: 170px;
  }
  .page-title-area.page-title-style-two {
    padding-top: 90px;
    padding-bottom: 70px;
  }
  .page-title-content h2 {
    font-size: 25px;
  }
  .page-title-content ul {
    margin-top: 15px;
  }
  .page-title-bg {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .page-title-bg h2 {
    font-size: 25px;
  }
  .page-title-bg form {
    margin-top: 20px;
    border-radius: 0;
    padding: 15px;
    max-width: 100%;
  }
  .page-title-bg form .form-group {
    border-right: none;
    margin-left: 0;
    margin-bottom: 10px;
  }
  .page-title-bg form .form-group label {
    left: 10px;
    top: 16px;
    font-size: 18px;
  }
  .page-title-bg form .form-group label .flaticon-category {
    position: relative;
    top: 1px;
  }
  .page-title-bg form .form-group .form-control {
    height: 50px;
    line-height: 50px;
    font-size: 13px;
    border: 1px solid #eeeeee !important;
    padding-left: 35px;
    padding-right: 10px;
  }
  .page-title-bg form .form-group.category-select {
    padding-right: 0;
  }
  .page-title-bg form .submit-btn button {
    margin-top: 5px;
  }
  .page-title-bg.map-home-area #main-full-map {
    height: 850px;
  }
  .widget-area {
    padding-left: 0;
    margin-top: 40px;
  }
  .widget-area.widget-left-sidebar {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .widget-area .widget {
    padding: 15px;
  }
  .widget-area .widget .widget-title {
    margin-bottom: 20px;
    font-size: 17px;
  }
  .widget-area .widget_vesax_posts_thumb .item .info .title {
    font-size: 15px;
  }
  .widget-area .widget_categories ul li {
    font-size: 14px;
  }
  .widget-area .widget_tag_cloud .widget-title {
    margin-bottom: 13px;
  }
  .widget-area .tagcloud a {
    font-size: 13px !important;
  }
  .listings-widget-area {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .listings-widget-area .widget {
    margin-bottom: 25px;
    padding-bottom: 25px;
  }
  .listings-widget-area .widget .widget-title {
    font-size: 16px;
  }
  .listings-widget-area .widget .widget-title::before {
    font-size: 16px;
  }
  .listings-widget-area.widget-right-sidebar {
    padding-left: 0;
    margin-top: 40px;
    margin-bottom: 0;
  }
  .listings-slides .owl-theme .owl-dots {
    display: none;
  }
  .listings-slides .owl-theme .owl-nav {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  .listings-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 16px;
    margin: 0 5px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    position: relative;
    left: 0;
    top: 0;
    transform: unset;
  }
  .listings-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }
  .single-listings-box .listings-image .owl-theme .owl-nav {
    opacity: 1 !important;
    visibility: visible !important;
  }
  .single-listings-box .listings-image .owl-theme .owl-nav [class*=owl-] {
    margin: 0 !important;
    left: 10px;
  }
  .single-listings-box .listings-image .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 10px;
  }
  .main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button {
    height: 50px;
    font-size: 14px;
    line-height: 49px;
    border: 1px solid #eeeeee;
    padding-left: 35px;
    padding-right: 10px;
  }
  .main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
    right: 15px;
  }
  .banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button {
    height: 50px;
    font-size: 14px;
    line-height: 49px;
    border: 1px solid #eeeeee;
    padding-left: 35px;
    padding-right: 10px;
  }
  .banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
    right: 15px;
  }
  .page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-button {
    height: 50px;
    font-size: 14px;
    line-height: 49px;
    border: 1px solid #eeeeee;
    padding-left: 35px;
    padding-right: 10px;
  }
  .page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
    right: 15px;
  }
  .pagination-area .ngx-pagination li.disabled {
    padding: 6px 10px;
  }
  .pagination-area .ngx-pagination li.current {
    padding: 6px 10px;
  }
  .pagination-area .ngx-pagination li a {
    padding: 6px 10px;
  }
  .listings-grid-sorting .ordering .ngx-dropdown-container {
    width: 180px !important;
  }
  .listings-grid-sorting .ordering .ngx-dropdown-container .ngx-dropdown-button {
    font-size: 13px !important;
  }
  .main-content .mean-container a.meanmenu-reveal {
    padding-top: 0;
    margin-top: -5px;
  }
  .main-content .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav {
    margin-top: 41px;
  }
}
/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }
  .map-home-area form {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
}
/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  .section-title {
    max-width: 580px;
    margin-bottom: 50px;
  }
  .section-title h2 {
    margin-bottom: 11px;
  }
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .ptb-70 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pt-70 {
    padding-top: 50px;
  }
  .pb-70 {
    padding-bottom: 50px;
  }
  .container {
    max-width: 720px;
  }
  .navbar-area.navbar-style-two .vesax-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--whiteColor);
  }
  .navbar-area.navbar-style-two .vesax-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--whiteColor);
  }
  .vesax-responsive-nav {
    display: block;
  }
  .vesax-responsive-nav .vesax-responsive-menu {
    position: relative;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav {
    margin-top: 57px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul li a.active {
    color: var(--mainColor);
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14.5px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 306px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .vesax-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--blackColor);
  }
  .vesax-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--blackColor);
  }
  .features-area.ptb-100 {
    padding-bottom: 50px;
  }
  .divider,
.divider2 {
    display: none;
  }
  .single-listings-box .listings-image .owl-theme .owl-nav {
    opacity: 1 !important;
    visibility: visible !important;
  }
  .single-listings-box .listings-image .owl-theme .owl-nav [class*=owl-] {
    margin: 0 !important;
  }
  .listings-slides .owl-theme .owl-dots {
    display: none;
  }
  .listings-slides .owl-theme .owl-nav {
    margin-top: 5px;
    opacity: 1;
    visibility: visible;
  }
  .listings-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 16px;
    margin: 0 5px;
    position: relative;
    left: 0;
    top: 0;
    transform: unset;
  }
  .listings-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }
  .feedback-slides .owl-theme .owl-dots {
    display: none;
  }
  .feedback-slides .owl-theme .owl-nav {
    margin-top: 5px;
    opacity: 1;
    visibility: visible;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    font-size: 16px;
    margin: 0 5px;
    position: relative;
    left: 0;
    top: 0;
    transform: unset;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    right: 0;
  }
  .category-area.pb-0 {
    padding-bottom: 50px !important;
  }
  .page-title-area {
    padding-bottom: 80px;
    padding-top: 170px;
  }
  .page-title-area.page-title-style-two {
    padding-bottom: 80px;
    padding-top: 100px;
  }
  .page-title-content ul {
    margin-top: 15px;
  }
  .page-title-bg {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .page-title-bg h2 {
    font-size: 35px;
  }
  .page-title-bg form {
    border-radius: 0;
    padding: 25px;
    margin-top: 30px;
  }
  .page-title-bg form .form-group {
    border-right: none;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .page-title-bg form .form-group label {
    left: 12px;
    top: 16px;
    font-size: 22px;
  }
  .page-title-bg form .form-group .form-control {
    border: 1px solid #eeeeee !important;
    height: 55px;
    line-height: 55px;
    padding-left: 40px;
    padding-right: 10px;
  }
  .page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-button {
    height: 55px;
    line-height: 55px;
    border: 1px solid #eeeeee;
    padding-left: 40px;
    padding-right: 10px;
  }
  .page-title-bg form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
    right: 15px;
  }
  .page-title-bg form .form-group.category-select {
    padding-right: 0;
    margin-left: 15px;
  }
  .page-title-bg form .submit-btn button {
    display: inline-block;
    width: auto;
    padding: 13px 40px;
    margin-top: 5px;
  }
  .page-title-bg.map-home-area #main-full-map {
    height: 700px;
  }
  .main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button {
    height: 55px;
    line-height: 51px;
    border: 1px solid #eeeeee;
    padding-left: 40px;
    padding-right: 10px;
  }
  .main-banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
    right: 15px;
  }
  .banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button {
    height: 55px;
    line-height: 51px;
    border: 1px solid #eeeeee;
    padding-left: 40px;
    padding-right: 10px;
  }
  .banner-content form .form-group .ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down {
    right: 15px;
  }
  .listings-widget-area {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .listings-widget-area .widget .widget-title {
    font-size: 18px;
  }
  .listings-widget-area.widget-right-sidebar {
    padding-left: 0;
    margin-top: 40px;
    margin-bottom: 0;
  }
  .listings-grid-sorting .ordering .ngx-dropdown-container {
    width: 145px !important;
  }
  .widget-area {
    padding-left: 0;
    margin-top: 40px;
  }
  .main-content .mean-container a.meanmenu-reveal {
    padding-top: 0;
    margin-top: -5px;
  }
  .main-content .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav {
    margin-top: 41px;
  }
}
/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
  .navbar-area.navbar-style-two .vesax-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--whiteColor);
  }
  .navbar-area.navbar-style-two .vesax-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--whiteColor);
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav {
    margin-top: 57px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul li a.active {
    color: var(--mainColor);
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14.5px;
  }
  .vesax-responsive-nav .vesax-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 306px;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .vesax-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--blackColor);
  }
  .vesax-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--blackColor);
  }
  .features-area.ptb-100 {
    padding-bottom: 70px;
  }
  .divider,
.divider2 {
    display: none;
  }
  .listings-slides .owl-theme .owl-nav [class*=owl-] {
    left: -15px;
  }
  .listings-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -15px;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    left: -15px;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -15px;
  }
  .page-title-area {
    padding-bottom: 100px;
    padding-top: 195px;
  }
  .page-title-area.page-title-style-two {
    padding-bottom: 100px;
  }
  .map-container.fw-map.side-full-map {
    margin-bottom: 40px;
    height: auto;
    padding-bottom: 0;
  }
  .map-container.fw-map.side-full-map #main-full-map {
    height: 600px;
  }
  .listings-widget-area {
    padding-right: 40px;
  }
  .listings-widget-area .widget_filters ul li button {
    font-size: 14px;
  }
  .listings-widget-area.widget-right-sidebar {
    padding-left: 40px;
  }
  .main-content .mean-container a.meanmenu-reveal {
    padding-top: 0;
    margin-top: -5px;
  }
  .main-content .vesax-responsive-nav .vesax-responsive-menu.mean-container .mean-nav {
    margin-top: 41px;
  }
}
/* Min width 1200px to Max width 1355px */
@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .container {
    max-width: 1140px;
  }
  .listings-slides .owl-theme .owl-nav [class*=owl-] {
    left: -15px;
  }
  .listings-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -15px;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-] {
    left: -15px;
  }
  .feedback-slides .owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -15px;
  }
}
/* Min width 1550px */
::ng-deep .p-calendar {
  width: 100%;
}
::ng-deep .p-dropdown {
  width: 100%;
}

.add-listings-box h3 {
  padding: 13px 25px !important;
  margin-bottom: 14px !important;
}

.add-listings-box .add-listings-btn button {
  font-size: 15px !important;
  min-height: 40px !important;
  height: 40px !important;
  line-height: 1 !important;
}
.add-listings-box .add-listings-btn button:hover {
  background: var(--mainColorHover) !important;
}

.add-listings-btn2 button {
  font-size: 15px !important;
  min-height: 40px;
  color: white !important;
  background-color: var(--mainColor) !important;
  border: 1px solid var(--mainColor) !important;
}
.add-listings-btn2 button:hover {
  background: var(--mainColorHover) !important;
}

.p-button {
  display: inline-block !important;
  background-color: var(--mainColor) !important;
  color: var(--whiteColor) !important;
  padding: 11px !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px !important;
  transition: var(--transition) !important;
  border: none !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  min-width: 45px;
}
.p-button:hover {
  background-color: var(--mainColorHover);
  color: #fff !important;
}

.list-arrow:last-child {
  margin-bottom: 0;
}
.list-arrow li {
  position: relative;
  list-style: none;
  color: #444;
  list-style: none;
  margin-bottom: 3px;
}
.list-arrow li:before {
  content: "\ea50";
  position: absolute;
  left: -21px;
  top: -1px;
  color: #7e7e7e;
  transition: var(--transition);
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-size: 18px;
}

.p-input-icon-left > i,
.p-input-icon-right > i {
  margin-top: 0 !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.65rem 0;
}

.view-button,
.p-button-rounded {
  border-radius: 50px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--mainColor) !important;
  width: 30px !important;
  height: 30px !important;
  border: none !important;
  box-shadow: none !important;
  overflow: visible;
  justify-content: center;
  min-width: auto;
  position: relative;
  min-height: auto !important;
}
.view-button:focus,
.p-button-rounded:focus {
  box-shadow: none !important;
}
.view-button:hover,
.p-button-rounded:hover {
  color: var(--mainColorHover) !important;
  background-color: white !important;
}
.view-button i,
.view-button span.pi,
.p-button-rounded i,
.p-button-rounded span.pi {
  position: relative;
  z-index: 2;
  font-size: 14px;
}
.view-button:after,
.p-button-rounded:after {
  content: "";
  display: block;
  background: #ddd;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transform: scale(1.3);
  transition: all ease 0.2s;
  opacity: 0;
}
.view-button:hover:after,
.p-button-rounded:hover:after {
  opacity: 1;
  transform: scale(1);
  background: #f6f6f6;
}
.view-button .p-button-label,
.p-button-rounded .p-button-label {
  display: none !important;
}

.view-button + .view-button,
.p-button-rounded + .p-button-rounded {
  margin-left: 7px;
}

.nowrap {
  white-space: nowrap;
}

.p-datepicker-trigger {
  background: #f9f9f9 !important;
  border: 1px solid #ced4da !important;
  color: #757ca0 !important;
  border-left: none !important;
  box-shadow: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.p-datepicker-trigger:hover {
  color: inherit !important;
}

.p-calendar-w-btn .p-inputtext {
  min-width: 100px;
}

.p-datatable .p-datatable-thead th {
  font-size: 0.8rem !important;
}
.p-datatable .p-datatable-tbody tr th {
  font-size: 0.8rem !important;
}
.p-datatable .p-datatable-tbody tr td {
  padding: 0.5rem 0.65rem !important;
  font-size: 0.8rem !important;
}
.p-datatable .p-datatable-tbody tr td .editInput {
  font-size: 0.8rem !important;
  width: 100%;
}
.p-datatable .p-datatable-tbody tr td .editInput:not([type=email]) {
  width: 100%;
}

.p-accordion-header-link {
  background: #ffffff !important;
  font-size: 0.9rem;
  font-weight: normal !important;
}
.p-accordion-header-link .pi {
  font-size: 0.6rem;
}

.p-accordion-tab:not(.p-accordion-tab-active) .p-toggleable-content {
  overflow: hidden !important;
}

.p-component label {
  font-size: 14px !important;
}
.p-component input {
  font-size: 14px !important;
}

.p-datatable-table .p-datatable-table tbody tr:last-child td {
  border-bottom: none;
}

.p-datatable .p-datatable-tbody td.inner-data {
  background-color: #f9f9f9 !important;
  padding: 0 !important;
}
.p-datatable .p-datatable-tbody td.inner-data .p-datatable-table tbody tr {
  background: transparent !important;
}

@media all and (min-width: 1200px) {
  .p-datatable-responsive-scroll > .p-datatable-wrapper > table {
    table-layout: fixed;
  }
}
.p-accordion-content .add-listings-box {
  box-shadow: none !important;
  border: 1px solid #e8e8e8;
}
.p-accordion-content .add-listings-box:last-child {
  margin-bottom: 0;
}
.p-accordion-content .add-listings-box label {
  margin-bottom: 4px !important;
}

@media screen and (max-width: 959px) {
  .p-element .p-datatable-table .p-datatable-tbody > tr > td {
    display: table-cell !important;
    width: auto !important;
  }
}
.table.table-bordered th {
  font-size: 0.8rem !important;
}
.table.table-bordered td {
  padding: 0.5rem 0.65rem !important;
  font-size: 0.8rem !important;
}

.secondary-btn .p-button,
.secondary-btn button {
  color: var(--mainColor) !important;
  border: 1px solid var(--mainColor) !important;
  box-shadow: none;
  font-size: 15px !important;
  min-height: 40px;
  background-color: transparent !important;
  border-radius: 5px !important;
  min-width: 40px;
  display: inline-block;
  vertical-align: top;
  padding: 10px !important;
  width: auto;
  height: 40px;
}
.secondary-btn .p-button:hover,
.secondary-btn button:hover {
  color: white !important;
  background-color: var(--mainColor) !important;
}
.secondary-btn .p-button-label {
  display: none;
}
.secondary-btn:after {
  display: none;
}

.p-dialog .p-dialog-footer {
  padding: 1rem 1.5rem 1.5rem 1.5rem !important;
  border-top: 1px solid #eee !important;
}

.h45 {
  height: 45px !important;
}

.fw500 {
  font-weight: 500 !important;
}

.add-listings-box .form-group .form-control[type=file] {
  height: 40px !important;
  background: #fff !important;
}
.add-listings-box .form-group .form-control[type=file]::-webkit-file-upload-button {
  height: 50px;
}

.p-accordion-content .add-listings-box label.mb-0 {
  margin-bottom: 0 !important;
}

.add-listings-box .form-group .form-control:not(textarea) {
  height: 40px !important;
}

.checkbox {
  display: inline-flex !important;
  align-items: center;
  background: #f8f8f8;
  padding: 8px 19px 8px 10px !important;
  border-radius: 5px;
  margin-right: 10px;
}
.checkbox input[type=checkbox] {
  margin-right: 5px !important;
}

.p-accordion-content .add-listings-box {
  box-shadow: none !important;
  border: 1px solid #e8e8e8;
}
.p-accordion-content .add-listings-box h3 {
  background: #f8f8f8;
  font-size: 15px !important;
}
.p-accordion-content .add-listings-box .form-group {
  margin-bottom: 15px !important;
}

.p-accordion .padfit .p-accordion-content {
  padding-top: 0.9rem !important;
  padding-bottom: 0 !important;
}

.viewSummary {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 13px !important;
  flex-wrap: wrap;
}

.viewSummary strong {
  font-weight: 500 !important;
}

.form-group.viewSummary label {
  margin: 0 10px 0 0 !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

.viewSummary div[type=text] {
  text-transform: capitalize;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.p-accordion .accordianStyle .p-accordion-content {
  background: #ebe8e1 !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.table.font-size-13 th {
  font-weight: 500 !important;
}

.viewSummary .form-group,
.viewSummary.form-group {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 13px !important;
  flex-wrap: wrap;
}

.viewSummary strong {
  font-weight: 500 !important;
}

.viewSummary .form-group label,
.viewSummary.form-group label {
  margin: 0 10px 0 0 !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

.viewSummary div[type=text] {
  text-transform: capitalize;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.p-accordion .accordianStyle .p-accordion-content,
.peach-bg {
  background: #FFF7f2 !important;
}

.table tr:last-child td {
  border-bottom: none;
}

@media only screen and (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}
.p-button-label {
  color: #fff !important;
}

.table-stamp {
  font-size: 13px !important;
}

.form-group {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .table_sticky_1col th:last-child,
.table_sticky_1col td:last-child,
.table_sticky_2col th:nth-last-child(-n+2),
.table_sticky_2col td:nth-last-child(-n+2),
.table_sticky_3col th:nth-last-child(-n+3),
.table_sticky_3col td:nth-last-child(-n+3),
.table_sticky_4col th:nth-last-child(-n+4),
.table_sticky_4col td:nth-last-child(-n+4) {
    position: sticky;
    right: 0;
    z-index: 0;
    background: #fff;
    padding: 0.5rem 0.65rem !important;
    width: 90px !important;
    min-width: 90px;
  }
  .table_sticky_1col th,
.table_sticky_2col th,
.table_sticky_3col th,
.table_sticky_4col th {
    background: #f8f9fa !important;
  }
  .table_sticky_1col th:last-child,
.table_sticky_1col td:last-child {
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
  .table_sticky_2col th:nth-last-child(2),
.table_sticky_2col td:nth-last-child(2) {
    right: 90px;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
  }
  .table_sticky_3col th:nth-last-child(2),
.table_sticky_3col td:nth-last-child(2) {
    right: 90px;
  }
  .table_sticky_3col th:nth-last-child(3),
.table_sticky_3col td:nth-last-child(3) {
    right: 180px;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
  }
  .table_sticky_4col th:nth-last-child(2),
.table_sticky_4col td:nth-last-child(2) {
    right: 90px;
  }
  .table_sticky_4col th:nth-last-child(3),
.table_sticky_4col td:nth-last-child(3) {
    right: 180px;
  }
  .table_sticky_4col th:nth-last-child(4),
.table_sticky_4col td:nth-last-child(4) {
    right: 270px;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
  }
}
.allergyhighlight.p-element .p-accordion-header-link {
  background: #f28797 !important;
  color: white !important;
}

.title-sm {
  font-size: 14px !important;
}

p-chart.p-element.baby-height canvas {
  height: 198px !important;
  display: block !important;
}

.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") no-repeat center center;
  background-size: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  opacity: 0.85;
  transition: all ease 0.3s;
}
.close-button:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transform: scale(1.1);
}

.imgPreview {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border: 1px solid #999;
  border-radius: 6px;
  position: relative;
  margin-left: 8px;
}

.imgPreview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgPreview a {
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: #ff5252;
  color: white;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  font-size: 10px;
}

.captureButton, .backButton {
  display: inline-flex;
  font-size: 22px;
  background: var(--mainColor);
  color: white;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0 0 4px #fff;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -15px;
  justify-content: center;
  align-items: center;
}
.captureButton:hover, .backButton:hover {
  background: #fff;
  color: var(--mainColor);
}

.backButton {
  background: #fff;
  border: 1px solid var(--mainColor);
  right: 3px;
  position: absolute;
  left: auto;
}
.backButton svg {
  width: 22px;
}
.backButton:hover {
  background: #fff;
  color: var(--mainColor);
  border-color: transparent;
}

.error {
  color: red;
}

.align {
  text-align: center !important;
}

div[id*=pr_id_] .p-datatable-table .p-datatable-tbody > tr > td {
  width: auto !important;
}

div[id^=pr_id_] .p-datatable-table .p-datatable-tbody > tr > td {
  width: auto !important;
}

div[id^=pr_id_] table[id$=-table] .p-datatable-tbody > tr > td {
  width: auto !important;
}

@media screen and (max-width: 960px) {
  div[id^=pr_id_] .p-datatable-table .p-datatable-tbody > tr > td {
    width: auto !important;
  }
}
.profile-nav-item .dropdown-toggle {
  padding: 0;
  color: var(--optionalColor);
  font-weight: 500;
}
.profile-nav-item .dropdown-toggle::after {
  display: none;
}
.profile-nav-item .dropdown-toggle::before {
  content: "\f106";
  position: absolute;
  right: 8px;
  transition: var(--transition);
  top: 50%;
  font-size: 15px;
  font-family: Flaticon;
  transform: translateY(-50%);
}
.profile-nav-item .dropdown-toggle span {
  top: 1px;
  color: var(--optionalColor);
}
.profile-nav-item .dropdown-toggle span::before {
  display: none;
}
.profile-nav-item .dropdown-toggle:hover::before {
  color: var(--mainColor);
}
.profile-nav-item .dropdown-toggle:hover span {
  color: var(--mainColor);
}
.profile-nav-item .menu-profile {
  padding-right: 20px;
  position: relative;
}
.profile-nav-item .menu-profile img {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 12px;
  border: 1px solid var(--mainColor);
  /* Add border around the image */
  border-radius: 50%;
  /* Make the image circular */
}
.profile-nav-item.dropdown .dropdown-menu {
  padding: 15px 15px 10px;
  min-width: 230px;
  max-width: 280px;
  position: absolute;
  right: 0;
  left: auto;
  border-radius: 2px;
  margin-top: 20px;
  border: 1px solid #eeeeee;
  display: block;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  background-color: var(--whiteColor);
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-header {
  padding: 0;
  border-bottom: 1px solid #f2f4f9;
  margin-left: -15px;
  margin-right: -15px;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-header .figure {
  position: relative;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-header .figure img {
  width: 80px;
  height: 80px;
  border: 1px solid var(--mainColor);
  /* Add border around the image */
  border-radius: 50%;
  /* Make the image circular */
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-header .name {
  display: block;
  color: var(--blackColor);
  margin-bottom: 2px;
  font-size: 17px;
  font-weight: 600;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-header .name::before {
  display: none;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-header .email {
  color: var(--optionalColor);
  font-size: 14px;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-body ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-body ul .nav-item {
  margin-left: 0;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-body ul .nav-item .nav-link {
  color: var(--blackColor);
  padding: 5px 15px 5px 38px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-body ul .nav-item .nav-link span {
  color: var(--blackColor);
  display: block;
  font-weight: 500;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-body ul .nav-item .nav-link span::before {
  display: none;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-body ul .nav-item .nav-link i {
  color: var(--mainColor);
  transition: var(--transition);
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-body ul .nav-item .nav-link:hover span {
  color: var(--mainColor);
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-body ul .nav-item .nav-link:hover i {
  transform: rotateY(-180deg);
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-footer {
  margin: 10px -15px 0;
  padding: 10px 15px 0;
  border-top: 1px solid #eeeeee;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-footer ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-footer ul .nav-item .nav-link {
  color: red;
  padding: 5px 15px 5px 38px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-footer ul .nav-item .nav-link span {
  color: red;
  display: block;
  font-weight: 500;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-footer ul .nav-item .nav-link span::before {
  display: none;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-footer ul .nav-item .nav-link i {
  color: red;
  transition: var(--transition);
  position: absolute;
  left: 15px;
  top: 7px;
  font-size: 16px;
}
.profile-nav-item.dropdown .dropdown-menu .dropdown-footer ul .nav-item .nav-link:hover i {
  transform: rotateY(-180deg);
}
.profile-nav-item.dropdown .dropdown-menu.show {
  margin-top: 10px;
  opacity: 1;
  visibility: visible;
}

.admin-btn {
  text-decoration: none !important;
  border: 1px solid #ccc !important;
  border-radius: 50px !important;
  padding: 3px 15px 3px 3px !important;
  background: #f6f6f6 !important;
}

.logout-text:hover {
  cursor: pointer;
}

.dropdown.profile-nav-item {
  position: absolute;
  right: 120px;
  top: 20px;
}
.dropdown.profile-nav-item .menu-profile img {
  width: 30px;
  height: 30px;
}

.p-datatable-responsive-scroll > .p-datatable-wrapper::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

/* Track */
.p-datatable-responsive-scroll > .p-datatable-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.p-datatable-responsive-scroll > .p-datatable-wrapper::-webkit-scrollbar-thumb {
  background: #c6c6c6;
}

/* Handle on hover */
.p-datatable-responsive-scroll > .p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}

.p-datatable-scrollable .p-datatable-thead, .p-datatable-scrollable .p-datatable-tbody, .p-datatable-scrollable .p-datatable-tfoot {
  display: table-row-group !important;
}

.p-datatable-scrollable .p-datatable-thead > tr, .p-datatable-scrollable .p-datatable-tbody > tr, .p-datatable-scrollable .p-datatable-tfoot > tr {
  display: table-row !important;
}

.p-datatable-scrollable .p-datatable-thead > tr > th, .p-datatable-scrollable .p-datatable-tbody > tr > td, .p-datatable-scrollable .p-datatable-tfoot > tr > td {
  display: table-cell !important;
  flex: unset !important;
}

.p-datatable-scrollable .p-datatable-thead > tr > th.hide, .p-datatable-scrollable .p-datatable-tbody > tr > td.hide {
  display: none !important;
}

.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: auto !important;
}

.p-datatable .p-paginator-bottom {
  border-width: 0;
}

.simplebar-track.simplebar-vertical {
  width: 10px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  width: 8px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  width: 8px;
  background: #999;
}
.simplebar-track.simplebar-vertical.simplebar-hover .simplebar-scrollbar:before {
  background: #444;
}

.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead {
  z-index: 3 !important;
}

@media only screen and (max-width: 726px) {
  .dropdown.profile-nav-item {
    position: absolute !important;
    right: 22px !important;
    top: 27px !important;
    z-index: 9999;
  }
  .dropdown.profile-nav-item .menu-profile {
    padding-right: 0px;
    line-height: normal;
  }
  .dropdown.profile-nav-item .menu-profile .name {
    font-size: 10px !important;
  }
  .dropdown.profile-nav-item .menu-profile img {
    margin-right: 0px !important;
    height: 25px !important;
    width: 25px !important;
  }
  .dropdown.profile-nav-item .dropdown-toggle.admin-btn:before,
.dropdown.profile-nav-item .dropdown-toggle.admin-btn .menu-profile span {
    display: none;
  }
  .dropdown.profile-nav-item .admin-btn {
    padding: 0px 0px 0px 0px !important;
    border: none !important;
  }
}
.p-paginator .p-paginator-current {
  height: auto !important;
  font-size: 13px;
}
.p-paginator .p-paginator-rpp-options.p-dropdown {
  height: 30px !important;
  width: 44px !important;
  text-align: center;
}
.p-paginator .p-paginator-rpp-options.p-dropdown .p-dropdown-label {
  font-size: 13px !important;
  padding: 3px 0 !important;
}
.p-paginator .p-dropdown .p-dropdown-trigger {
  width: 1rem;
}
.p-paginator .p-dropdown .p-dropdown-trigger .pi {
  font-size: 10px;
}

.p-paginator .p-paginator-pages .p-paginator-page, .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  height: 1.8rem !important;
  min-width: 1.8rem !important;
  font-size: 13px;
  padding: 0 !important;
}

.p-paginator .p-paginator-element .pi {
  font-size: 12px;
}

@media (max-width: 768px) {
  .p-paginator-rpp-options.p-dropdown {
    height: 30px !important;
  }
  .p-datatable .p-paginator-bottom {
    padding: 0 I !important;
  }
  .p-paginator .p-paginator-current {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .p-paginator-pages button.p-paginator-element:nth-child(n+3) {
    display: none !important;
  }
}
.p-multiselect .p-multiselect-trigger {
  width: 22px !important;
}

.p-multiselect-trigger-icon {
  font-size: 0.7rem !important;
}

.p-component {
  font-size: 13px !important;
}

#capturewin .p-dialog {
  max-width: 90%;
}

#capturewin .p-dialog video {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .dataBydate {
    overflow-x: scroll !important;
  }
}